<template>
  <div class="recordItem">
    <div class="rec_name">{{title}}</div>

    <div class="rec_info">
      <div class="rec_time">{{createTime}}</div>
      <!-- <div class="rec_subtit">test</div> -->
    </div>

    <div class="rec_tag_line">
      <div class="rec_tag_wrap">
        <div class="rec_tag">已成功</div>
      </div>
      <!-- <div class="tag_btn_wrap">
        <div class="goExam">去考试</div>
      </div> -->
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      title: {
        type: String
      },
      createTime: {
        type: [String,Number]
      }
    },
    data() {
      return {

      }
    }
  }
</script>
  
<style>
  .recordItem {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .rec_name {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .rec_info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .rec_time {
    color: #8b8b8b;
  }

  .rec_subtit {
    font-weight: bold;
    color: #3d79f8;
  }

  .rec_tag_line {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .rec_tag_wrap {
    display: flex;
  }

  .rec_tag {
    font-size: 10px;
    border-radius: 50px;
    padding: 4px 6px;
    background-color: #3e78fe;
    color: white;
  }

  .goExam {
    padding: 4px 18px;
    border: 1px solid #e7e7e7;
    border-radius: 50px;
    font-size: 10px;
    color: black;
  }
</style>