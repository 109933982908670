<template>
  <div class="act_box" @click="goDetail()">
    <div class="act_pic">
      <img :src="img" >
    </div>

    <div class="act_tit">
      <div class="act_type">
        【{{type}}】
      </div>
      <div class="act_text">
        {{title}}
      </div>
    </div>

    <div class="act_info">
      <div class="act_time">
        <div class="act_time_s">开始时间：{{startTime}}</div>
        <div class="act_time_e">截至时间：{{endTime}}</div>
      </div>
      <div class="act_record">
        <!-- 已报名：5 -->
      </div>
    </div>

    <div class="act_tag">
      <div class="tag price" v-if="price>0">￥{{price}}</div>
      <div class="tag free" v-else>免费</div>
      <div class="tag state">需审核</div>
    </div>

  </div>
</template>
  
<script>
  export default {
    props: {
      type: {
        type: String
      },
      actId: {
        type: [Number,String]
      },
      title: {
        type: String
      },
      img: {
        type: String
      },
      startTime: {
        type: String
      },
      endTime: {
        type: String
      },
      limit: {
        type: [Number,String]
      },
      price: {
        default: 0,
        type: [Number,String]
      },
      audit: {
        type: Boolean
      }
    },
    data() {
      return {

      }
    },
    methods: {
      goDetail() {
        this.$router.push({
          name: 'ActDetail',
          query: {
            actId: this.actId
          }
        })
      }
    }
  }
</script>
  
<style>
  .act_box {
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
  }

  .act_pic {
    width: 100%;
    height: 100px;
    background-color: #debae7;
  }
  .act_pic>img {
    width: 100%;
    height: 100%;
  }

  .act_tit {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    margin-top: 6px;
  }
  .act_type {
    color: #3d7eed;
    font-weight: bold;
  }
  .act_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    margin-top: 4px;
  }
  .act_time {
    display: flex;
    flex-direction: column;
    color: #a5a5a5;
  }
  .act_record {
    color: #ed7129;
    font-weight: bold;
  }
  .act_time_s {
    margin-bottom: 2px;
  }
  .act_tag {
    display: flex;
    margin-top: 6px;
  }
  .tag {
    padding: 4px 6px;
    border-radius: 30px;
    font-size: 10px;
    margin-right: 10px;
    color: white;
  }
  .price {
    background-color: #dd473c;
  }
  .free {
    background-color: #4abd44;
  }
  .state {
    background-color: #e8a949;
  }
</style>