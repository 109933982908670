<template>
  <div id="content" class="container" style="background-color: #f5f5f5;">
    <nav-header :title="header_title"></nav-header>

    <div class="paper">

      <div class="filter">
        <div class="opt" @click="active=0" :class="{'active': active==0}">报名中心</div>
        <div class="opt" @click="active=1" :class="{'active': active==1}">报名记录</div>
        <div class="opt" @click="active=2" :class="{'active': active==2}">报名订单</div>
      </div>

      <div class="search" v-if="active==0">
        <input type="text" class="search_ipt" placeholder="搜索活动">
      </div>

      <template v-if="active==0">
        <div class="box">
          <ActivityBox 
            v-for="item,index in list"
            :actId="item.id"
            :title="item.title"
            :img="item.banner"
            :startTime="item.startTime"
            :endTime="item.endTime"
            :limit="item.maxJoin"
            :price="item.price"
            :audit="item.audit"
            :type="item.activityType_dictText"
            :key="index"
          ></ActivityBox>
        </div>
      </template>

      <template v-if="active==1">
        <div class="box">
          <!-- <RecordItem/> -->
          <RecordItem
            v-for="item,index in records"
            :title="item.activityId_dictText"
            :createTime="item.createdAt"
            :key="index"
          ></RecordItem>
        </div>
      </template>

      <template v-if="active==2">
        <div class="box">
          <OrderItem :state="1"></OrderItem>
          <OrderItem :state="0"></OrderItem>
          <OrderItem :state="0"></OrderItem>
          <OrderItem :state="0"></OrderItem>
          <OrderItem :state="0"></OrderItem>
          <OrderItem :state="0"></OrderItem>
        </div>
      </template>
      

    </div>
    
  </div>
</template>
  
<script>
import NavHeader from "../../components/nav-header";
import ActivityBox from "./components/activityBox.vue";
import RecordItem from "./components/recordItem.vue";
import OrderItem from "./components/orderItem.vue";

  export default {
    components: {
      NavHeader,
      ActivityBox,
      RecordItem,
      OrderItem
    },
    data() {
      return {
        header_title: '活动报名',
        active: 0,
        listParams: {
          page: 1,
          size: 10,
        },
        recordsParams: {
          page: 1,
          size: 10,
          userId: 0
        },
        userId: null,
        listTotal: 0,
        list: [],
        recordsTotal: 0,
        records: []
      }
    },
    watch: {
      active: {
        handler(val) {
          console.log('watch', val)
          switch(val) {
            case 0:
              if(!this.list.length) {
                this.getList()
              }
              break;
            case 1:
              if(!this.records.length) {
                this.getRecords()
              }
          }
        }
      }
    },
    activated() {
      window.addEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    mounted() {
      setTimeout(()=>{
        console.log('userId',this.recordsParams.userId)
      },100)
      this.getList()
    },
    methods: {
      ScrollToBottomEvt() {
        const el = document.getElementById("content");
        const toBottom = el.scrollHeight - window.screen.height - el.scrollTop;
        if (toBottom < 10) {
          // this.getList(true);
        }
      },
      getList(more = false) {
        if(more) {
          this.listParams.page ++
        }

        this.$api.Activity.List(this.listParams).then((res)=>{
          this.listTotal = res.data.total
          this.list = res.data.records
          console.log('list', res.data.records)
        })
      },
      getRecords(more = false) {
        this.recordsParams.userId = this.$store.state.user.id
        console.log('getRecords')
        if(more) {
          this.listParams.page ++
        }

        this.$api.Activity.Record(this.recordsParams).then((res)=>{
          let list = res.data.records
          this.recordsTotal = res.data.total
          this.records = list
          console.log(list)
          // console.log('recordssss', res.data.records)
          // list.forEach((item)=>{
          //   this.$api.Activity.Detail(item.id)
          //   .then((res)=>{
          //     this.records.push(res.data)
          //   })
            
          // })
          // console.log('records',this.records)
        })
      }
    }
  }
</script>
  
<style lang="less">
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 0 40px;
    position: sticky;
    top: 50px;
    z-index: 9999999;
  }

  .opt {
    // width: 33.33%;
    text-align: center;
    color: #252525;
    padding: 8px 0;
    font-weight: bold;
    font-size: 12px;
    display: inline-block;
    box-sizing: border-box;
  }

  .active {
    color: #3979e7;
    border-bottom: 3px solid #3979e7;
  }

  .paper {
    margin-top: 50px;
  }

  .search {
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
  }

  .search_ipt {
    border: none;
    border-radius: 50px;
    background-color: #f8f8f8;
    width: 100%;
    height: 30px;
    text-align: center;
  }

  .box {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
</style>