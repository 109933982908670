<template>
  <div class="recordItem">
    <div class="rec_name">考试20241022</div>

    <div class="rec_info">
      <div class="rec_time">2024-10-22 09:18:17</div>
    </div>

    <div class="rec_tag_line">
      <div class="rec_tag_wrap">
        <div class="rec_subtit">0.01</div>
      </div>
      <div class="tag_btn_wrap">
        <div class="tag_btn pay" v-if="state==0">待支付</div>
        <div class="tag_btn success" v-if="state==1">支付成功</div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      state: {
        default: 0,
        type: [Number,String]
      }
    },
    data() {
      return {

      }
    }
  }
</script>
  
<style>
  .recordItem {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .rec_name {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .rec_info {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }

  .rec_time {
    color: #8b8b8b;
  }

  .rec_subtit {
    font-weight: bold;
    color: #3d79f8;
  }

  .rec_tag_line {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .rec_tag_wrap {
    display: flex;
  }

  .rec_tag {
    font-size: 10px;
    border-radius: 50px;
    padding: 4px 6px;
    background-color: #3e78fe;
    color: white;
  }

  .tag_btn {
    padding: 4px 10px;
    border: 1px solid #e7e7e7;
    border-radius: 50px;
    font-size: 10px;
    overflow: hidden;
    color: white;
  }

  .success {
    background: #49bd44;
  }

  .pay {
    background-color: #eba84a;
  }
</style>